<template>
  <div class="cost">
    <div class="_title" v-if="title">费用支付</div>
    <div class="_content" :class="className">
      <v-card flat class="mt-4">
        <v-card-text class="pa-0">
          <div class="form-title">支付信息：</div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            style="width: 100%; margin: auto"
          >
            <div class="d-flex align-center">
              <div class="label mr-6" style="width: 90px; text-align: end">
                会议金额:
              </div>
              <v-radio-group v-model="cost.productId" row>
                <v-radio
                  v-for="(item, i) in list"
                  :key="i"
                  :label="item.title"
                  :value="item.id"
                ></v-radio>
              </v-radio-group>
            </div>

            <div class="d-flex align-center">
              <div class="label mr-6" style="width: 90px; text-align: end">
                支付方式:
              </div>
              <v-radio-group v-model="cost.chargeType" row>
                <v-radio label="微信" value="1"></v-radio>
                <v-radio label="支付宝" value="2"></v-radio>
                <!--                <v-radio label="银联" value="3"></v-radio>-->
              </v-radio-group>
            </div>

            <div class="d-flex align-center" :class="mlClass">
              <div class="pr-4">
                <div class="mt-6">
                  <p class="_red">
                    如您在使用扫码支付时发生限额提醒，请于第二天支付，或采用银行转账方式支付。由此带来的不便敬请谅解。
                  </p>
                  <p class="_red">
                    在校学生代表参会须凭在校学生证现场领取会议资料。
                  </p>
                </div>
                <div class="mt-6">
                  <p class="bold desc-title">说明</p>
                  <p>(1) 缴费方式：在线支付（推荐）、银行汇款、现场缴费。</p>
                  <p>
                    (2) 缴费标准以支付时间为准。会务组收到注册费后视为有效注册。
                  </p>
                  <p>
                    (3) 线下缴费但未能参会者，注册费不予退回，可由他人代替参会。
                  </p>
                  <p>(4) 现场缴费者不保证会议资料和酒店预订。</p>
                </div>
                <hr />
                <div class="mt-6">
                  <p class="bold desc-title">
                    如果您使用银行转账请参考以下信息:
                  </p>
                  <p>
                    银行转账信息:<br />
                    收款单位：石家庄博瑞迪生物技术有限公司 <br />
                    统一社会信用代码：91130101MA08GDWT8E<br />
                    住所：石家庄高新区黄河大道136号科技中心6层C区611室<br />
                    开户银行：中国建设银行股份有限公司石家庄开发区支行<br />
                    地址：河北省石家庄市长江大道9号<br />
                    帐号：1305 0161 2008 0000 1734<br />
                    电话：0311-66695178<br />
                    （请务必附言：缴费类型：会议费/培训费/会议费+培训费，参会代表姓名+联系电话+
                    2021年分子植物育种大会；集体汇款的单位请列出每位参会代表姓名。）
                  </p>
                </div>
              </div>
            </div>
          </v-form>
          <!-- <div class="form-title">发票信息：</div>
          <v-form
            ref="form2"
            v-model="valid"
            lazy-validation
            :style="formStyle"
          >
            <div class="d-flex align-center">
              <div
                class="label mr-3 required"
                :class="$store.state.navigation ? 'w250' : 'w170'"
              >
                发票类型：
              </div>
              <v-radio-group  @change='radioChange' v-model="cost.invoiceType" row>
                <v-radio label="增值税普通(电子)发票" value="10"></v-radio>
                <v-radio label="增值税普通(纸质)发票" value="20"></v-radio>
                <v-radio label="增值税专用(纸质)发票" value="30"></v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex align-center">
              <div
                class="label mr-3 required w170"
            
              >
                获取方式：
              </div>
              <v-radio-group  @change='radioChange2' v-model="cost.sendType" row>
                <v-radio v-if="cost.invoiceType==10" label="电子邮箱" value="1"></v-radio>
                <v-radio v-if="cost.invoiceType!=10" label="快递邮寄" value="3"></v-radio>
                <v-radio v-if="cost.invoiceType!=10" label="会场自取" value="2"></v-radio>
               
              </v-radio-group>
            </div>
             <div class="d-flex align-center" v-if="cost.sendType==2" >
              <div
                class="label"
                :style='$store.state.navigation?"width:150px;":"width:220px;"'
              >
              </div>
              <div style='color:red;font-size:12px;'>
                缴费开票时间不得晚于11月15日
              </div>
            </div>
            <div class="d-flex justify-center align-center" v-if="cost.sendType==3">
              <div
                class="label required w170"
              
              >
                邮寄地址：
              </div>
              <v-text-field
                v-model="cost.address"
                :rules="rules.address"
                required
              />
            </div>

            <div class="d-flex justify-center align-center" v-if="cost.sendType==3">
              <div
                class="label required w170"
             
              >
                联系人姓名：
              </div>
              <v-text-field
                v-model="cost.contactName"
                :rules="rules.contactName"
                required
              />
            </div>

            <div class="d-flex justify-center align-center" v-if="cost.sendType==3">
              <div
                class="label required w170"
               
              >
                联系人手机号：
              </div>
              <v-text-field
                v-model="cost.contactPhone"
                :rules="rules.contactPhone"
                required
              />
            </div>

            <div class="d-flex justify-center align-center" v-if="cost.invoiceType==10">
              <div
                class="label required w170"
             
              >
                电子邮箱：
              </div>
              <v-text-field
                v-model="cost.mailbox"
                :rules="rules.mailbox"
                required
              />
            </div>
           

            <div class="d-flex justify-center align-center">
              <div
                class="label w170 required"
              
              >
                单位/公司名称：
              </div>
              <v-text-field v-model="cost.companyName" />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label required w170"
               
              >
                统一社会信用代码：
              </div>
              <v-text-field
                v-model="cost.socialCreditCode"
                :rules="rules.socialCreditCode"
                required
              />
            </div>

            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
                :class="{required:cost.invoiceType==30}"
              >
                单位/公司注册地址：
              </div>
              <v-text-field
                v-model="cost.companyAddress"
                :rules="cost.invoiceType==30?rules.companyAddress:rules.empty"
                required
              />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
               :class="{required:cost.invoiceType==30}"
              >
                电话：
              </div>
              <v-text-field
                v-model="cost.phone"
                :rules="cost.invoiceType==30?rules.phone:rules.empty"
                required
              />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
               :class="{required:cost.invoiceType==30}"
              >
                开户行名称：
              </div>
              <v-text-field v-model="cost.bank" :rules="cost.invoiceType==30?rules.bank:rules.empty" required />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
              :class="{required:cost.invoiceType==30}"
              >
                银行账号：
              </div>
              <v-text-field
                v-model="cost.account"
                :rules="cost.invoiceType==30?rules.account:rules.empty"
                required
              />
            </div>
            <div class="d-flex justify-center align-center">
              <div
                class="label  w170"
              
              >
                发票备注：
              </div>
              <v-text-field
                v-model="cost.remarks"
         
              />
            </div>
          </v-form> -->
          <v-btn
            style="min-width: 0 !important; width: 300px; margin: 0 auto"
            :disabled="!valid"
            color="success"
            block
            class="mt-4"
            @click="costAction"
          >
            提交支付
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      list: [],
      cost: {
        productId: '1',
        chargeType: "1",
        invoiceOrg: "",
        taxLabel: "",
        invoiceType: "10",
        socialCreditCode: "",
        companyName: "",
        address: "",
        phone: "",
        bank: "",
        account: "",
        contactName:"",
        mailbox:"",
        companyAddress:"",
        sendType:"1",
        contactPhone:"",
        remarks:''
      },
      rules: {
        productId: [(v) => !!v || "请选择支付金额"],
        chargeType: [(v) => !!v || "请选择支付方式"],
        invoiceOrg: [(v) => !!v || "请输入发票抬头"],
        taxLabel: [(v) => !!v || "请输入纳税人识别号"],
        invoiceType: [(v) => !!v || "请选择发票类型"],
        socialCreditCode: [(v) => !!v || "请输入统一社会信用代码"],
        address: [(v) => !!v || "请输入邮寄地址"],
        phone: [(v) => !!v || "请输入电话"],
        bank: [(v) => !!v || "请输入开户行名称"],
        account: [(v) => !!v || "请输入银行账号"],
         sendType: [(v) => !!v || "请选择获取方式"],
          contactName: [(v) => !!v || "请输入联系人姓名"],
           mailbox: [(v) => !!v || "请输入电子邮箱"],
            companyAddress: [(v) => !!v || "请输入单位/公司注册地址"],
            companyName: [(v) => !!v || "请输入单位/公司名称"],
             contactPhone: [(v) => !!v || "请输入联系人手机号"],
             
         
        empty:[true]
      },
    };
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 100%; ";
        case "sm":
          return "width: 100%; ";
        case "md":
          return "width: 95%; ";
        case "lg":
          return "width: 80%; ";
        case "xl":
          return "width: 70%; ";
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    mlClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "ml-2";
        case "sm":
          return "ml-12";
        case "md":
          return "ml-100";
        case "lg":
          return "ml-100";
        case "xl":
          return "ml-100";
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
     
    },
    resetForm(){
      this.cost=Object.assign(this.cost,{
        taxLabel: "",
        socialCreditCode: "",
        companyName: "",
        address: "",
        phone: "",
        bank: "",
        account: "",
        contactName:"",
        mailbox:"",
        companyAddress:"",
        contactPhone:"",
        remarks:""
      })
    },
    radioChange(val){
      if(val==10){
        this.cost.sendType="1"
      }else{
         this.cost.sendType="3"
      }
      this.resetForm()
       this.$refs.form2.resetValidation()
        
    },
    radioChange2(){
      this.resetForm()
       this.$refs.form2.resetValidation()
       
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();

      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    getList() {
      this.$request({
        url: "/product/list",
        method: "post",
        data: { type: 1 },
      }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.reverse();
          // price order desc
        }
      });
    },
    costAction() {
      if (this.isWeiXin()) {
        this.$message.tips("请在默认浏览器中打开，完成支付");
        return;
      }
      let cost = Object.assign({}, this.cost);
      if (this.$store.state.navigation) {
        if (cost.chargeType == 1) {
          cost.chargeType = 5;
        }
        if (cost.chargeType == 2) {
          cost.chargeType = 3;
        }
      }

      if (this.$refs.form.validate()) {
        this.$request({
          url: "/charge/apply",
          method: "post",
          data: cost,
        }).then((res) => {
          if (res.code == 200) {
            if (cost.chargeType == 1 || cost.chargeType == 5) {
              this.list.map((item) => {
                if (item.id == cost.productId) {
                  cost.price = item.price;
                }
              });
              if (this.$store.state.navigation) {
                window.location.href =
                  res.data.url +
                  "&redirect_url=https%3A%2F%2Fwww.molecularbreeding.cn%2FpaySuccess%3Forder%3D" +
                  res.data.orderId;
              } else {
                this.$router.push({
                  path: "/payOrder",
                  query: {
                    cost: JSON.stringify(cost),
                    order: JSON.stringify(res.data),
                  },
                });
              }
            }
            if (cost.chargeType == 2 || cost.chargeType == 3) {
              let divform = document.getElementsByTagName("divform");
              if (divform.length) {
                document.body.removeChild(divform[0]);
              }
              const div = document.createElement("divform");
              div.innerHTML = res.data.url;
              document.body.appendChild(div);

              div.firstChild.acceptCharset = "UTF-8";
              div.firstChild.submit();
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cost {
  padding: 0 10px;
  padding-bottom: 150px;
 
  .w250 {
    width: 250px !important;
    text-align: end;
  }

  .w170 {
    width: 170px !important;
    text-align: end;
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  .ml-100 {
    margin-left: 100px;
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  .form-title {
    font-size: 18px;
    background: #efefef;
    color: #3b86aa;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-radius: 10px;
    font-weight: bold;
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  ._red {
    color: #e53333;
  }
}
</style>
